<template>
  <div>

    <Overlay :overlay="overlay"/>

    <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
      <v-col cols="12" lg="3">
        <v-text-field v-model.trim="search"
                      class="rounded-input"
                      clearable
                      dense
                      hide-details
                      outlined
                      placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                      single-line/>
      </v-col>
      <v-col class="text-end" cols="12" lg="9">
        <v-btn class="mr-2" color="primary"
               depressed
               @click="$refs.regionalForm.open(null,'Ajouter un compte régional')">
          <v-icon left>mdi-plus</v-icon>
          Régional
        </v-btn>

        <v-btn color="primary" icon @click="$emit('refresh')">
          <v-icon>mdi-table-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider/>

    <v-card-text>

      <v-skeleton-loader v-if="isLoading" type="table"/>

      <v-data-table v-if="regionals.length"
                    :headers="headers"
                    :items="regionals"
                    :items-per-page="10"
                    :search="search"
                    class="table-border rounded-lg"
      >
        <template v-slot:item.actions="{ item,index }">
          <div class="d-flex">
            <v-btn color="red" disabled icon @click="$emit('delete',[item.id,index])">
              <v-icon size="20">mdi-trash-can</v-icon>
            </v-btn>
            <v-btn color="" icon
                   @click="$refs.regionalForm.open(item,'Modifier un compte régional')">
              <v-icon size="20">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn color=""
                   icon
                   @click="$refs.regionalDetailDialog.open(item)">
              <v-icon size="20">mdi-eye-plus</v-icon>
            </v-btn>
            <v-btn color=""
                   icon>
              <v-icon size="20">mdi-chart-pie</v-icon>
            </v-btn>
          </div>
        </template>


        <template v-slot:item.name="{ item }">
          <div class="d-flex text-no-wrap align-center  text-truncate">

            <v-avatar v-if="item.photo" size="38">
              <v-img :src="$baseUrl + item.photo"
                     class="zoom-pointer"
                     @click="$refs.lightbox.open(item.photo)"></v-img>
            </v-avatar>

            <v-avatar v-else
                      :class="$func.randomColor()"
                      class="font-weight-medium"
                      size="38">
              {{ $func.avatarName(item.name) }}
            </v-avatar>

            <span class="ml-2"> {{ item.name }}</span>
          </div>
        </template>


        <template v-slot:item.is_active="{ item }">
          <v-switch v-model="item.is_active"
                    class="pa-0 ma-0"
                    color="secondary"
                    hide-details
                    readonly
                    @click="$emit('change-status',item.id)"/>
        </template>

        <template v-slot:item.status="{ item }">

          <v-chip v-if="item.is_active"
                  class="font-weight-medium bg-success-subtle"
                  small
                  text-color="success">Actif
          </v-chip>

          <v-chip v-else
                  class="font-weight-medium bg-red-subtle"
                  small
                  text-color="red">Inactif
          </v-chip>

        </template>

        <template v-slot:item.email="{  item }">
          <a :href="'mailto:'+item.email"
             :style="{maxWidth: '150px'}"
             class="d-block text-truncate orange--text text-decoration-none">
            {{ item.email }}
          </a>
        </template>

        <template v-slot:item.supervisors="{  item }">
          <v-chip :disabled="!item.supervisors.length"
                  class="font-weight-medium bg-primary-subtle"
                  small
                  text-color="primary"
                  @click="$refs.supervisorsListDialog.open(item.supervisors)">
            {{ item.supervisors.length }} Superviseur(s)
            <v-icon color="primary" right small>mdi-arrow-right</v-icon>
          </v-chip>
        </template>

      </v-data-table>

      <NoResult v-else/>

    </v-card-text>

    <Lightbox ref="lightbox"/>
    <RegionalForm ref="regionalForm" @refresh="$emit('refresh')"/>
    <RegionalDetailDialog ref="regionalDetailDialog"/>
    <SupervisorsListDialog ref="supervisorsListDialog"/>

  </div>
</template>

<script>
import RegionalForm from "@/views/regionals/components/RegionalForm.vue";
import RegionalDetailDialog from "@/views/regionals/components/RegionalDetailDialog.vue";
import SupervisorsListDialog from "@/views/regionals/components/SupervisorsListDialog.vue";


export default {
    components: {RegionalForm, RegionalDetailDialog, SupervisorsListDialog},
    props: {
        regionals: {
            type: Array,
            default: Array,
        },
        isLoading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            overlay: false,
            search: '',
        }
    },
    methods: {},
    computed: {
        headers() {
            let _headers = [
                {
                    text: 'Statut',
                    value: 'is_active',
                    show: true
                },
                {
                    text: 'Nom',
                    value: 'name',
                    show: true
                },
                {
                    text: 'N°Téléphone',
                    value: 'phone',
                    show: true
                },
                {
                    text: 'Email',
                    value: 'email',
                    show: true
                },
                {
                    text: 'Superviseurs',
                    value: 'supervisors',
                    align: 'center',
                    show: true
                },
                {
                    text: 'Compte',
                    value: 'status',
                    show: true
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    show: true
                },
            ]

            return _headers.filter(x => x.show)
        },
    },

}
</script>

<style scoped>

</style>