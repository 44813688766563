import { render, staticRenderFns } from "./TableView.vue?vue&type=template&id=9bfa0c38&scoped=true&"
import script from "./TableView.vue?vue&type=script&lang=js&"
export * from "./TableView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bfa0c38",
  null
  
)

export default component.exports